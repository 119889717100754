<template>
  <v-dialog v-model="isOpenModel" :rtl="true" :width="width" scrollable>
    <template #activator="{ props: propsDialouge }">
      <div v-if="!withVmodel">
        <slot name="activator" :props="propsDialouge" />
      </div>
    </template>
    <template #default>
      <v-card class="custom-text-bg">
        <slot name="content" :is-open="isOpen" :close="close" />
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">

const isOpen = ref(false)

function close() {
  props.withVmodel ? emit('update:modelValue', false) : (isOpen.value = false)
}
const emit = defineEmits(['update:modelValue'])
const isOpenModel = computed({
  get() {
    return props.withVmodel ? props.modelValue : isOpen.value
  },

  set(value) {
    props.withVmodel ? emit('update:modelValue', value) : (isOpen.value = value)
  }
})
const props = withDefaults(
  defineProps<{
    width: string,
    withVmodel?: boolean,
    modelValue?: boolean
  }>(),
  {
    withVmodel: false,
    width: 'auto'
  }
)

</script>
<style scoped>
.custom-text-bg {
  @apply bg-bg_color text-text_color !important;
}
</style>
